<script setup>
import { ref } from 'vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'

defineProps({
  errors: Object,
  showClose: {
    type: Boolean,
    default: false,
  },
  classes: {
    type: String,
    default: 'w-5/6',
  },
})

let close = ref(false)
</script>

<template>
  <div
    v-show="Object.entries(errors).length > 0 && !close"
    class="border border-4 rounded-md p-3 border-red-600 mt-5"
    :class="classes"
  >
    <div class="mb-3 relative">
      <span class="text-xl">There's a problem</span>
      <XMarkIcon
        v-if="showClose"
        class="absolute right-0 top-0 w-5 cursor-pointer"
        @click="close = true"
      />
    </div>
    <div
      v-for="(error, key) in errors"
      :key="key"
    >
      <p class="text-sm font-bold text-red-600 hover:underline">
        <a :href="'#' + key">
          {{ error }}
        </a>
      </p>
    </div>
  </div>
</template>
